import * as React from 'react'
import { Component } from 'react';
import { Row, Col, Input, Form, Spin } from 'antd-min';
import { RouteComponentProps } from "react-router-dom";
import { withRouter, GLForm, GLFormComponentProps, GLGlobal, FormItemsLayout, FormHelper } from "gl-commonui";
import { SearchService, SearchProps } from '../../../service/admin/search';
import { GSAdminLocale } from '../../../locales/localeid';

export interface SearchPageProps {}
export interface SearchPageState {
    text?: string
    data?: any
    loading?: boolean
}

@withRouter
@GLForm.create()
export class SearchPage extends Component<RouteComponentProps<any> & GLFormComponentProps, SearchPageState> {
    searchService = new SearchService()
    map = new Map();
    flag = false
    constructor(props, context) {
        super(props, context);
        this.state = { loading: false }
    }

    renderSearchResult (values) {
        this.setState({ loading: true });
        this.searchService.search({keyword: values.search.trim()}).then(data => {
            this.flag = true;
            this.setState({
                text: values && values.search,
                loading: false,
                data: data
            });
        });
    }

    formatData(v, k) {
        let categoryTitle;
        let hits = v.hits.map(function(hit, idx) {
            hit.category = v.category;
            return hit;
        });

        switch(v.category) {
            case "School":
                categoryTitle = "Schools";
                break;
            case "Campus":
                categoryTitle = "Campuses";
                break;
            case "Class":
                categoryTitle = "Classes";
                break;
            case "Student":
                categoryTitle = "Students";
                break;
            case "User":
                categoryTitle = "Users";
                break;
        }

        if (this.map.has(categoryTitle)) {
            let list = this.map.get(categoryTitle);
            this.map.set(categoryTitle, hits.concat(list));
        } else {
            this.map.set(categoryTitle, hits);
        }
    }

    renderList(category, list) {
        let items = [];
        let categoryTitle;
        const fmt = GLGlobal.intl.formatMessage;
        switch(category) {
            case "Schools":
                categoryTitle = fmt({ id: GSAdminLocale.SearchCategorySchools });
                break;
            case "Campuses":
                categoryTitle = fmt({ id: GSAdminLocale.SearchCategoryCampuses });
                break;
            case "Classes":
                categoryTitle = fmt({ id: GSAdminLocale.SearchCategoryClasses });
                break;
            case "Students":
                categoryTitle = fmt({ id: GSAdminLocale.SearchCategoryStudents });
                break;
            case "Users":
                categoryTitle = fmt({ id: GSAdminLocale.SearchCategoryUsers });
                break;
        }
        items = list.map((v, k) => {
            return this.renderMultiLinks(v, k);
        })
        
        return <Row key={category} className="category">
            <Row className="categorytitle">{categoryTitle}</Row>
            <Row className="list">{items}</Row>
        </Row>
    }

    renderMultiLinks(v, k) {
        return <Col key={k} className="underline">
            {
                v.links.map((link, index) => {
                    return <Col key={index} className="link">
                        <a href={link.url} target="_blank" title={link.title}>{link.title}</a>
                    </Col>
                })
            }
        </Col>
    }

    validateFields() {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.renderSearchResult(values);
            }
        });
    }

    render() {
        const form = this.props.form;
        const { renderFormItem } = FormHelper;
        const options = {
            formItemProps: { label: null }
        }
        const search = GLGlobal.intl.formatMessage({ id: GSAdminLocale.Search });
        let text, html = [];
        this.state.data && this.state.data.forEach((v, k) => {
            this.formatData(v, k);
        });
        this.map.forEach((v, k) => {
            html.push(this.renderList(k, v));
        });
        this.map.clear();

        if(!html.length) {
            text = <>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.NoResult })}<b>{this.state.text}</b></>;
        } else {
            text = <>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.ResultTitle })}<b>{this.state.text}</b></>;
        }
        return <div className='content-layout search-container'>
            <div className={'page-title main-title ' + (this.flag ? "hide" : "show")}>
                <h1>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.Search })}</h1>
            </div>
            <div className='page-content' style={ !this.flag ? {} : {marginTop:0}} >
                <div className="content-header search-form">
                    <GLForm form={form}  >
                        <FormItemsLayout colTotal={1} >
                            {renderFormItem({...form, ...options}, GSAdminLocale.SearchText, SearchProps.search,
                                <Input.Search placeholder={search} onSearch={() => this.validateFields()} enterButton />, null, true, [{ whitespace: true }])}
                        </FormItemsLayout>
                    </GLForm>
                </div>
                <Spin spinning={this.state.loading}>
                    <Row className={this.flag ? "show" : "hide"}>
                        <Row className="resulttitle">{text}</Row>
                        <Row className="result">{html}</Row>
                    </Row>
                </Spin>
            </div>
        </div>
    }
}